<template>
  <v-row>
    <v-col cols="12">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
        <v-form @submit.prevent="handleSubmit(login)">
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              name="Usuario"
              rules="required"
            >
              <v-text-field
                v-model="user.username"
                label="Usuario"
                name="username"
                autocomplete="new-password"
                prepend-icon="mdi-account"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="Contraseña"
              rules="required"
            >
              <v-text-field
                v-model="user.password"
                label="Contraseña"
                type="password"
                name="password"
                autocomplete="new-password"
                prepend-icon="mdi-key"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary darken-1"
              tile
              type="submit"
              :block="$vuetify.breakpoint.smAndDown"
              :disabled="invalid"
            >
              Iniciar sesión
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </v-col>
    <v-col cols="12">
      <v-divider class="primary"></v-divider>
    </v-col>
    <!--    <v-col cols="12" class="text-center">-->
    <!--      <v-img-->
    <!--        height="41px"-->
    <!--        contain-->
    <!--        :src="require('@/assets/ms-symbollockup_signin_light.svg')"-->
    <!--      ></v-img>-->
    <!--    </v-col>-->
  </v-row>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

extend("required", {
  ...required,
  message: "{_field_} no puede estar vacio.",
});
export default {
  name: "Login",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    user: {
      username: null,
      password: null,
    },
  }),
  methods: {
    login() {
      this.$store.dispatch("working", true);
      this.$http
        .post("login", {
          username: this.user.username,
          password: this.user.password,
        })
        .then((res) => {
          // this.$store.dispatch("customer/tmpPhone", this.phone);
          this.$toast.success("Bienvenido(a) " + res.data.user.name);
          this.$store.dispatch("user", res.data.user);
          this.$store.dispatch("token", res.data.token);
          this.$http.defaults.headers["Authorization"] =
            "Bearer " + res.data.token;

          this.$router.push({ name: "app" });
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            this.$toast.error(error.response.data.msg);
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped></style>
